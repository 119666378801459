<template>
  <div
    class="fullScreen has-text-left is-flex is-flex-direction-column is-align-items-center is-justify-content-flex-start"
  >
    <p class="title is-5 mb-1">Devices</p>

    <div class="mt-0 mb-4 columns width-100 searchBar">
      <div class="column is-2" />
      <div class="column is-8">

        <autocomplete :search="search" auto-select @submit="selectDevice" class="autoComplete">
          <template #result="{ result, props }">
            <li v-bind="props" class="autocomplete-result wiki-result" >
              <div class="wiki-title">
                {{ result.name }}
              </div>
              <!-- <div class="wiki-snippet" v-html="result.snippet" /> -->
            </li>
          </template>
        </autocomplete>
        <!-- <b-autocomplete
          v-model="search"
          placeholder="e.g. iPhone X"
          :keep-first="false"
          :open-on-focus="true"
          :data="devicesList"
          field="name"
          @select="option => (selected = option)"
          :clearable="true"
        ></b-autocomplete> -->
      </div>
      <div class="column is-2" />
    </div>

    <div class="boxGrid maxWidth">
      
      <div v-if="selected">
        <!-- {{ selected }} -->
        <div @click="selectDevice(selected)"
          class="customBox is-clickable is-flex is-flex-direction-column is-align-items-center is-justify-content-center">
          <img class="deviceImage mb-3" src="@/assets/iPhone-11.png" />
          <p>
            <strong>{{ selected.name }}</strong>
          </p>
        </div>
      </div>
      <div v-else @click="selectDevice(device)"
        class="customBox is-clickable is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
        v-for="device in devicesList" :key="device.name" >
        <img class="deviceImage mb-3" src="@/assets/iPhone-11.png" />
        <p>
          <strong>{{ device.name }}</strong>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Autocomplete from '@trevoreyre/autocomplete-vue';
import '@trevoreyre/autocomplete-vue/dist/style.css';

import { mapGetters } from "vuex";
import store from "@/store";

export default {
  name: "Devices",
  title: "Devices",
  components: {
    Autocomplete
  },
  computed: {
    filteredDataObj() {
      return this.devicesList.filter(option => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(this.name.toLowerCase()) >= 0
        );
      });
    },
    ...mapGetters({
      devicesList: "getDevicesList"
    })
  },
  data() {
    return {
      // search: "",
      selected: null
    };
  },
  methods: {
    selectDevice(device) {
      store.dispatch("selectDevice", device);
    },
    search(input) {
      if (input.length < 1) { return [] }
      return this.devicesList.filter(device => {
        return device.name.toLowerCase()
          .startsWith(input.toLowerCase())
      })
    },
  }
};
</script>


<style lang="scss" scoped>
// .searchBar {
//   min-height: 50px;
// }
// @media only screen and (max-width: 768px) {
//   .searchBar {
//     min-height: 70px;
//   }
// }
// .autoComplete {
//   margin-bottom: 10px !important;
// }
</style>